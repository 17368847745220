<template>
  <div>
    <div class="reslutTitle">
        <img src="../../../assets/images/wanfangLogo2.png" alt="">
        <p>送检论文查重结果</p>
    </div>
    <div class="reslut">
        <div class="title">
            <p>{{orderInfo.title}}</p>
            <button v-if="orderInfo.orderStatus == '21'" @click="download(orderInfo.reportInfo.zip)">下载报告</button>
            <button v-else disabled>下载报告</button>
        </div>
        <div class="reslutInfo">
            <p>作者：<span>{{orderInfo.author}}</span></p>
            <p>送检时间：<span>{{orderInfo.createTimeText}}</span></p>
            <p>送检状态：<span>{{orderInfo.statusText}}</span></p>
        </div>
        <div class="reslutRate">重复率：
            <span :class="orderInfo.repetitionRate<0.25 ? 'rate_pass':'rate_noPass'" v-if="orderInfo.orderStatus == '21'">{{orderInfo.repetitionRateText}}</span>
            <span v-else>--</span>
        </div>
        <button class="reslutBtn" v-if="orderInfo.orderStatus == '21' && orderInfo.repetitionRate<0.25" @click="goDCIInput()">DCI申领</button>
        <button class="reslutBtn" v-else disabled>DCI申领</button>
    </div>
  </div>
</template>

<script>
import { queryCheckOrder } from "@/api/EvidenceService";
export default {
  components: {},
  props: {
      checkOrderNo: String,
  },
  data () {
    return {
        // checkOrderNo: '900-8361018-228768',
        orderInfo: {},
    }
  },
  created() {
    //   console.log('checkOrderNo-------------:',this.checkOrderNo);
    //   this.orderNo = this.checkOrderNo;
      this.getQueryCheckOrder(this.checkOrderNo);
      let that = this;
      setInterval(function(){
          that.getQueryCheckOrder(that.checkOrderNo);
      },30000)
  },
  methods: {
      getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '12':return '检测中';
          case '20':return '待处理';
          case '21':return '已完成';
          case '31':return '失败';
          case '40':return '已取消';
        }
    },
      getQueryCheckOrder(checkOrderNo){
        let that = this;
        queryCheckOrder(checkOrderNo).then(({ data }) => {
            data.createTimeText = that.dayjs(data.createTime * 1000).format("YYYY-MM-DD");
            data.statusText=that.getStatus(data.orderStatus);
            data.repetitionRateText = (data.repetitionRate * 100).toFixed(2) + "%"
            that.orderInfo = data;
        });
      },

    //  下载报告
    download(zip){
      window.open(zip, "_self");  //_self，是不用打开新窗口
    },

    // 跳转到第二部DCi申领
    goDCIInput(){
        this.$parent.changeStep('2')
    },

  },
}
</script>

<style lang="scss" scoped>
    .reslutTitle{
        display: flex;
        align-content: center;
        img{
            width: 125px;
            height: 30px;
            margin-right: 10px;
        }
        p{
            font-size: 14px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            color: #8C8C8C;
            line-height: 30px;
        }
    }
    .reslut{
        background: #F9F9F9;
        box-shadow: 0px 0px 2px 0px rgba(0,30,62,0.18);
        padding: 20px;
        margin: 20px 0;
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            p{
                font-size: 20px;
                font-family: 'PingFangSC, PingFang SC';
                font-weight: 500;
                color: rgba(0,18,22,0.85);
                line-height: 36px;
                width: calc(100% - 100px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            button{
                width: 88px;
                height: 32px;
                line-height: 30px;
                border-radius: 4px;
                border: 1px solid #0063DA;
                font-size: 16px;
                font-family: 'PingFang-SC, PingFang-SC';
                color: #0063DA;
                background: none;
                cursor: pointer;
            }
            button:disabled{
                background: #ECEFF6;
                border: 1px solid #D9D9D9;
                color: #ACACAC;
                cursor: no-drop;
            }
        }
        .reslutInfo{
            margin-bottom: 10px;
            p{
                display: inline-block;
                margin-right: 40px;
                font-size: 16px;
                font-family: 'PingFangSC, PingFang SC';
                color: rgba(0,18,22,0.45);
                line-height: 24px;
                span{
                    color: #001216;
                    line-height: 24px;
                }
            }
        }
        .reslutRate{
            margin-bottom: 20px;
            font-size: 16px;
            font-family: 'PingFangSC, PingFang SC';
            color: rgba(0,18,22,0.45);
            line-height: 24px;
            span{
                line-height: 24px;
            }
            span.rate_pass{
                color: #62BA46;
            }
            span.rate_noPass{
                color: #FF4D4F;
            }
        }
        .reslutBtn{
            width: 130px;
            height: 40px;
            background: #316EF4;
            border-radius: 4px;
            font-size: 14px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            font-weight: 500;
            color: #FFFFFF;
            line-height: 38px;
            border: none;
            cursor: pointer;
        }
        .reslutBtn:disabled{
            background: #ECEFF6;
            border: 1px solid #D9D9D9;
            color: #ACACAC;
            cursor: no-drop;
        }
    }
</style>
