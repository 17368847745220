<template>
  <div class="DCIRegister">
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="130px">
      <div v-if="menuIndex == 1">
        <div class="infoBox">
          <div class="title">作品信息确认</div>
          <div class="infoList">
            <div><span>作品名称</span><p v-if="orderInfo.subject">{{orderInfo.subject}}</p></div>
            <div><span>发表状态</span><p v-if="orderInfo.publishStatus">{{ constants.publishStatus[orderInfo.publishStatus * 1-1].label }}</p></div>
            <div><span>创作性质</span><p v-if="orderInfo.creationProperty">{{ constants.creationNature[orderInfo.creationProperty * 1-1].label }}</p></div>
            <div><span>权利范围</span><p>全部</p></div>
            <div><span>作品类别</span><p v-if="orderInfo.worksType">{{ constants.worksType[orderInfo.worksType * 1-1].label }}</p></div>
            <div><span>首次发表日期</span><p v-if="orderInfo.publishTimeText">{{ orderInfo.publishTimeText }}</p></div>
            <div><span>创作完成日期</span><p v-if="orderInfo.finishTimeText">{{ orderInfo.finishTimeText }}</p></div>
            <div><span>权利取得方式</span><p v-if="orderInfo.obtainType">{{ constants.obtainType[orderInfo.obtainType * 1-1].label }}</p></div>
            <div><span>DCI申领时间</span><p v-if="orderInfo.applyObtainTimeText">{{orderInfo.applyObtainTimeText}}</p></div>
            <div><span>首次发表地点</span><p v-if="orderInfo.publishLocation">{{orderInfo.publishLocation.province}}{{orderInfo.publishLocation.city}}</p></div>
            <div><span>创作完成地点</span><p v-if="orderInfo.completeLocation">{{orderInfo.completeLocation.province}}{{orderInfo.completeLocation.city}}</p></div>
            <div><span>权利归属方式</span><p v-if="orderInfo.belong">{{ constants.ownership[orderInfo.belong * 1-1].label }}</p></div>
            <div><span>著作权人</span><p v-if="obligeeDetail.userName">{{obligeeDetail.userName}}</p></div>
          </div>
        </div>

        <div class="infoBox">
          <div class="title">作品信息补充</div>
          <!-- <el-form-item label="著作权人地址" prop="authorSignatureLocation"> -->
            <!-- <el-input v-model="form.authorSignatureLocation" disabled /> -->
            <!-- <el-input v-model="orderInfo.authors[0].address" disabled /> -->
          <!-- </el-form-item> -->
          <el-form-item label="作品创作目的" prop="creationIntention.text">
            <el-input class="contentTextarea" type="textarea" placeholder="请输入作品创作目的(不超过50字)" v-model="form.creationIntention.text"></el-input>
          </el-form-item>
          <el-form-item label="作品创作过程" prop="creationOriginality.text">
            <el-input class="contentTextarea" type="textarea" placeholder="请输入作品创作过程(不超过85字)" v-model="form.creationOriginality.text"></el-input>
          </el-form-item>
          <el-form-item label="作品独创性" prop="creationProcess.text">
            <el-input class="contentTextarea" type="textarea" placeholder="请输入作品独创性(不超过140字)" v-model="form.creationProcess.text"></el-input>
          </el-form-item>
        </div>
        <!-- <div class="infoBox">
          <div class="title">文件上传</div>
          <el-form-item label="他人作品授权文件" prop="file">
            <el-upload
              class="commonUpload"
              drag
              :action="uploadUrl"
              :headers="uploadHeaders"
              :on-success="uploadSuccess"
              :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
              :before-upload="uploadBefore"
              multiple
              :file-list="fileList"
            ><i class="iconfont iconUpload">&#xe606;</i><p>上传文件</p></el-upload>
            <p class="uploadTips">如有他人作品授权的则上传授权文件。</p>
          </el-form-item>
          <el-form-item label="其他文件" prop="file">
            <el-upload
              class="commonUpload"
              drag
              :action="uploadUrl"
              :headers="uploadHeaders"
              :on-success="uploadSuccess"
              :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
              :before-upload="uploadBefore"
              multiple
              :file-list="fileList"
            ><i class="iconfont iconUpload">&#xe606;</i><p>上传文件</p></el-upload>
          </el-form-item>
        </div> -->
        <el-form-item prop="agreement" style="margin-bottom: 30px;margin-top: 30px;" class="agreement">
          <el-checkbox-group v-model="agreement" >
            <el-checkbox>我已知晓：创作说明及上传资料请务必规范认真填写，否则会导致登记失败及DCI被撤销。</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item class="submitRow">
          <el-button class="submit" type="submit" @click="toNext()">下一步</el-button>
          <el-button class="cancel" @click="cancel('form')">取消</el-button>
        </el-form-item>
      </div>
      
      <div v-if="menuIndex == 2">
        <div class="infoBox" style="margin-top: 20px;">
          <div class="title">订单确认</div>
          <div class="infoList">
            <div><span>作品名称</span><p v-if="orderInfo.subject">{{orderInfo.subject}}</p></div>
            <div><span>发表状态</span><p v-if="orderInfo.publishStatus">{{ constants.publishStatus[orderInfo.publishStatus * 1-1].label }}</p></div>
            <div><span>创作性质</span><p v-if="orderInfo.creationProperty">{{ constants.creationNature[orderInfo.creationProperty * 1-1].label }}</p></div>
            <div><span>权利范围</span><p>全部</p></div>
            <div><span>作品类别</span><p v-if="orderInfo.worksType">{{ constants.worksType[orderInfo.worksType * 1-1].label }}</p></div>
            <div><span>首次发表日期</span><p v-if="orderInfo.publishTimeText">{{ orderInfo.publishTimeText }}</p></div>
            <div><span>创作完成日期</span><p v-if="orderInfo.finishTimeText">{{ orderInfo.finishTimeText }}</p></div>
            <div><span>权利取得方式</span><p v-if="orderInfo.obtainType">{{ constants.obtainType[orderInfo.obtainType * 1-1].label }}</p></div>
            <div><span>DCI申领时间</span><p v-if="orderInfo.applyObtainTimeText">{{orderInfo.applyObtainTimeText}}</p></div>
            <div><span>首次发表地点</span><p v-if="orderInfo.publishLocation">{{orderInfo.publishLocation.province}}{{orderInfo.publishLocation.city}}</p></div>
            <div><span>创作完成地点</span><p v-if="orderInfo.completeLocation">{{orderInfo.completeLocation.province}}{{orderInfo.completeLocation.city}}</p></div>
            <div><span>权利归属方式</span><p v-if="orderInfo.belong">{{ constants.ownership[orderInfo.belong * 1 - 1].label }}</p></div>
            <div><span>著作权人</span><p v-if="obligeeDetail.userName">{{obligeeDetail.userName}}</p></div>

            <div class="infoListBox2"><span>作品创作目的</span><p v-if="form.creationIntention.text">{{form.creationIntention.text}}</p></div>
            <div class="infoListBox2"><span>作品创作过程</span><p v-if="form.creationOriginality.text">{{form.creationOriginality.text}}</p></div>
            <div class="infoListBox2"><span>创作独创性</span><p v-if="form.creationProcess.text">{{form.creationProcess.text}}</p></div>
          </div>

          <el-form-item class="amountBox">
            <div class="amount">应付金额：<p>¥ {{form.orderAmount}}</p></div>
          </el-form-item>
          <!-- <el-form-item label="支付方式" prop="paymode" class="choosePayStyle is-required">
              <el-radio-group v-model="form.paymode">
                <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe678; </i> <span>微信支付</span></el-radio>
                <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe675; </i> <span>支付宝支付</span></el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item class="submitRow">
            <el-button class="cancel" type="submit" @click="menuIndex = 1">上一步</el-button>
            <el-button class="submit" type="submit" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <pay-dialog ref="payDialog" payForm="DCIRegister" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as auth from '../../../utils/auth'
import constants from "@/utils/constants";
import multipleSelect from "../../../components/form/multipleSelect";
import { queryObligeeDetail } from "@/api/ObligeeService";
import { queryDCIRegistrationOrder,applyRegisterOrder } from "@/api/EvidenceService"
import PayDialog from "../../../components/PayDialog";
export default {
  components: {multipleSelect,PayDialog},
  props: {
      DCIOrderNo: String,
  },
  data () {
    return {
      // DCIOrderNo: "900-8358904-299671",

      constants,
      menuIndex: 1,
      agreement: true,
      orderInfo: {},
      obligeeDetail: {},
      fileList: [],
      form: {
        // authorSignatureLocation: '',  // 著作权人地址
        issuingAuthority: "NATIONAL", 
        creationIntention:{ //作品创作目的
          text:"",
        },
        creationOriginality:{ //作品创作过程
          text:"",
        },
        creationProcess:{ //创作独创性
          text:""
        },
        extra:{
            dciOrderNo:"",
        },
        orderAmount: '1.00',
        paymode:"alipay",
      },
      rules: {
        'creationIntention.text': [
          { required: true, validator: this.valiText1, trigger: "blur" },
        ],
        'creationOriginality.text': [
          { required: true, validator: this.valiText2, trigger: "blur" },
        ],
        'creationProcess.text': [
          { required: true, validator: this.valiText3, trigger: "blur" },
        ],
      },
    }
  },
  created() {
    let DCIOrderNoText = '';
    if(this.$route.query.DCIOrderNo){    // DCIOrderNo 如果是个人中心我的DCI页面，点击数登申请按钮跳转过来的
      DCIOrderNoText = this.$route.query.DCIOrderNo;
    }else{  // 正常三步流程下来，props传参
      DCIOrderNoText = this.DCIOrderNo;
    }

    // 获取DCI数登详细信息
    queryDCIRegistrationOrder(DCIOrderNoText).then(({ data }) => {
      // console.log('获取DCI数登详细信息',data);
        this.orderInfo = data;
        if(this.orderInfo.completeTime){
          this.orderInfo.finishTimeText = this.dayjs(data.completeTime * 1000).format("YYYY-MM-DD HH:mm:ss ");
        }
        if(this.orderInfo.publishTime){
          this.orderInfo.publishTimeText = this.dayjs(data.publishTime * 1000).format("YYYY-MM-DD HH:mm:ss ");
        }
        if(this.orderInfo.applyObtainTime){
          this.orderInfo.applyObtainTimeText = this.dayjs(data.applyObtainTime * 1000).format("YYYY-MM-DD HH:mm:ss ");
        }
        this.form = Object.assign({}, this.form, data);  // 合并两个对象，作为登记参数
        this.form.extra.dciOrderNo = data.orderNo;

        // 获取相关 权利人/作者，以及权利人编码
        queryObligeeDetail(data.obligee[0]).then(({ data }) => {
          // console.log('获取权利人相关信息',data);
          this.obligeeDetail = data;
        });
    });

  },
  methods: {
    // 自定义验证
    valiText1(rule, value, callback){
      if(this.form.creationIntention.text == ""){
        callback(new Error("请输入作品创作目的"));
        return;
      }
      if(this.form.creationIntention.text.length > 50){
        callback(new Error("作品创作目的不能超过50字"));
        return;
      }
      callback();
    },
    valiText2(rule, value, callback){
      if(this.form.creationOriginality.text == ""){
        callback(new Error("请输入作品创作过程"));
        return;
      }
      if(this.form.creationOriginality.text.length > 85){
        callback(new Error("作品创作过程不能超过85字"));
        return;
      }
      callback();
    },
    valiText3(rule, value, callback){
      if(this.form.creationProcess.text == ""){
        callback(new Error("请输入创作独创性"));
        return;
      }
      if(this.form.creationProcess.text.length > 140){
        callback(new Error("创作独创性不能超过140字"));
        return;
      }
      callback();
    },
    // 下一步
    async toNext(){
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        // this.mixinsScrollView();
        return;
      }
      this.menuIndex = 2;
    },
     // 点击取消按钮，清空输入框
    cancel(formName){
        this.$refs[formName].resetFields();//点击取消按钮，清空el-input
    },

    // 提交支付
    onSubmit(){
      if(this.order){ // 如果已经提交过订单了，就阻止重复提交
        this.$message.error('请勿重复提交订单');
        return false
      }
      applyRegisterOrder(this.form).then(({ data }) => {
        this.order = data;
        // console.log("order::::::::::::",this.order);
        // if (data.paymentStatus == "21") {
        //   this.bus.$emit("refreshVip");
        //   this.onSetPayDialogClose(true);
        // } else {
        //   this.$refs.payDialog.showDialog(data);
        //   this.isPayDialogVisible = true;
        // }
        // 不用支付回调，直接到列表页面支付
        this.onSetPayDialogClose(true);
      });
    },
    onSetPayDialogClose(result) {
      console.log(result);
      // if (result) {   // 别人的支付接口，获取不到状态
        //跳转至详情页
        this.$router.push({
          // path: "/Register/Detail",
          path: "/Mine/MyRegister",
          // query: { orderNo: this.order.orderNo },
        });
      // }
    },

  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/mine.scss';
/deep/.el-form-item__label{
  text-align: left;
}
.DCIRegister {
    .DCITitle{
      font-size: 24px;
      font-family: 'SourceHanSansCN, SourceHanSansCN';
      font-weight: 500;
      color: #333333;
      line-height: 36px;
      margin-bottom: 30px;
      // padding-left: 16px;
      position: relative;
    }
    // .DCITitle::before{
    //   content: '';
    //   width: 4px;
    //   height: 24px;
    //   background: #007DFF;
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   transform: translate(0, -50%);
    //   border-radius: 2px;
    // }
    .searchBar{
      height: 40px;
      .title{
        height: 40px;
        line-height: 40px;
      }
      .icon{
        font-weight: bold;
      }
    }

    .infoBox{
      padding: 20px 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px 0px rgba(0,70,144,0.09);
      margin-bottom: 20px;
      .title{
        font-size: 20px;
        font-family: 'PingFangSC, PingFang SC';
        font-weight: 500;
        color: rgba(0,18,22,0.85);
        line-height: 36px;
        position: relative;
        margin-bottom: 10px;
      }
      .title::before{content: '';width: 4px;height: 20px;border-radius: 2px;background: #027DFF;position: absolute;top: 8px;left: -12px;}
      .infoList{
        display: flex;
        flex-wrap: wrap;
	      justify-content: flex-start;
        padding-bottom: 22px;
        div{
          width: 50%;
          display: flex;
          align-content: center;
          height: 40px;
          span{display: block;width: 130px;line-height: 40px;font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 500;color: rgba(0,18,22,0.45);}
          p{width: calc(100% - 130px);line-height: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;font-family: 'PingFangSC, PingFang SC';color: #001216;padding-right: 20px;}
          .infoFile{color: #316EF4;}
        }
        .infoListBox2{
          width: 100%;
          height: auto;
          p{white-space: inherit;text-overflow: inherit;overflow: inherit;line-height: 30px;padding: 5px 0;}
        }
      }
      .contentTextarea{
        /deep/textarea{height: 120px;padding: 10px 15px;}
      }
    }

    .commonUpload{width: 110px;display: inline-block;margin-right: 10px;}
    /deep/.el-upload-dragger{height: 32px;width: 110px;padding: 0 10px;display: flex;align-items: center;justify-content: center;
      i{margin-right: 6px;}
    }
    .uploadTips{font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 400;color: rgba(0,0,0,0.65);line-height: 32px;display: inline-block;}

    .agreement /deep/.el-form-item__content,.submitRow /deep/.el-form-item__content{margin-left: 0!important;}
    /deep/.el-checkbox-group{line-height: 20px;}
    /deep/.el-checkbox__label{color: rgba(0,0,0,0.65)!important;}
    .submitRow button{
      width: 120px;height: 40px;line-height: 38px;background: #ECEFF6;border-radius: 4px;font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;color: #333333;padding: 0;border: none;outline: none;
    }
    .submitRow button:hover{border: none;outline: none;}
    .submitRow button.submit{background: #316EF4;color: #FFFFFF;}
    .submitRow button:nth-child(1){margin-right: 20px;}

    .linkage{width: 100%;}
    /deep/.linkage div{width: 48%;}
    /deep/.linkage div .el-select{width: 100%;}
    /deep/.linkage div .el-select .el-input{width: 100%;}
    .applyTypeBox::after{content: '';width: 0;height: 0;border-top: 24px solid #316EF4;border-left: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;right: 0;}
    .ant-row /deep/.el-form-item__content{margin-left: 0!important;}
    .ant-row /deep/.el-form-item__label{white-space: nowrap;}
    .ant-row /deep/.el-form-item__content{margin-right: 20px;}
    /deep/.el-radio-group,.el-radio{display: flex;align-items: center;height: 40px;line-height: 40px;}
    /deep/.el-radio__label{display: flex;align-items: center;height: 40px;line-height: 40px;}
    /deep/.el-checkbox-group{line-height: 20px;}
    .amount{display: flex;align-items: center;font-size: 14px;font-weight: 500;color: #000000;margin-bottom: -20px;border-top: 1px solid #ECEFF6;padding-top: 22px;padding-bottom: 22px;
      p{color: #316EF4;font-size: 22px;}
    }
    .aliPayColor{font-size: 28px;margin-right: 5px;}
    .weChatColor{font-size: 24px;margin-right: 7px;}
    .amountBox /deep/.el-form-item__content,.agreement /deep/.el-form-item__content{margin-left: 0!important;}
}

/deep/.el-checkbox,.el-checkbox__input{
    display: flex;
    align-items: center;
    white-space: normal;
}
@media screen and (max-width: 991px){
  .DCIRegister .infoBox .infoList{display: block!important;}
  .DCIRegister .infoBox .infoList div{width: 100%!important;}
}
</style>
