<template>
  <div class="ComprehensiveInputForm">
      <div class="container">
          <div class="formTitle">
              <span :class="formStep == '1' || formStep == '1-2' ? 'active':''">论文查重</span>
              <span :class="formStep == '2' || formStep == '2-2' ? 'active':''">DCI申领</span>
              <span :class="formStep == '3' ? 'active':''">版权登记</span>
          </div>
          <div class="formContent">
              <div class="content">
                  <DuplicateCheck v-if="formStep == '1'"></DuplicateCheck>
                  <DuplicateCheckReslut v-if="formStep == '1-2'" :checkOrderNo="checkOrderNo"></DuplicateCheckReslut>
                  <DCIInputForm v-if="formStep == '2'" :checkOrderNo="checkOrderNo"></DCIInputForm>
                  <DCIInputFormReslut v-if="formStep == '2-2'" :DCIOrderNo="DCIOrderNo"></DCIInputFormReslut>
                  <DCIRegister v-if="formStep == '3'" :DCIOrderNo="DCIOrderNo"></DCIRegister>
              </div>
              <div class="contentTips">
                  <div><p><span>1</span>论文查重</p><span>论文查重率低于25%，用户可继续申领DCI，否则无法申领。</span></div>
                  <div><p><span>2</span>DCI申领</p><span>查重率合格后，即可快速申领DCI确认权属。</span></div>
                  <div><p><span>3</span>版权登记</p><span>凭DCI可直接申请国家版权局数字作品版权登记证书。</span></div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import DuplicateCheck from './components/DuplicateCheck.vue'
import DuplicateCheckReslut from './components/DuplicateCheckReslut.vue'
import DCIInputForm from './components/DCIInputForm.vue'
import DCIInputFormReslut from './components/DCIInputFormReslut.vue'
import DCIRegister from './components/DCIRegister.vue'
export default {
  components: {
      DuplicateCheck,
      DuplicateCheckReslut,
      DCIInputForm,
      DCIInputFormReslut,
      DCIRegister,
  },
  data () {
    return {
        formStep: '1',
        checkOrderNo: '',
        DCIOrderNo: '',
    }
  },
  created() {
      if(this.$route.query.formStep){
          this.formStep = this.$route.query.formStep;
      }
  },
  methods: {
      changeStep(newValue,OrderNo){
          this.formStep = newValue;
          if(newValue == '1-2' && OrderNo){
            this.checkOrderNo = OrderNo;
          }else if(newValue == '2-2' && OrderNo){
              this.DCIOrderNo = OrderNo;
          }
      },
  },
}
</script>

<style lang="scss" scoped>
    .ComprehensiveInputForm{
        padding: 20px 0 50px 0;
        .formTitle{
            width: 100%;
            height: 56px;
            padding: 8px 40px 0 40px;
            background: #ECEFF6;
            border-radius: 12px 12px 0px 0px;
            display: flex;
            align-items: center;
            span{
                display: block;
                width: calc(100% / 3);
                height: 48px;
                line-height: 48px;
                font-size: 20px;
                font-family: 'SourceHanSansCN, SourceHanSansCN';
                color: #333333;
                text-align: center;
            }
            span.active{
                background: url(../../assets/images/ComprehensiveInputFormTtitleBg.png)no-repeat 100%;
                background-size: 100% 100%;
                color: #316EF4;
            }
        }
        .formContent{
            background: #FFFFFF;
            border-radius: 0 0 4px 4px;
            display: flex;
            padding: 40px 0;
            .content{
                width: calc(100% - 320px);
                padding: 0 40px;
            }
            .contentTips{
                height: 680px;
                width: 320px;
                padding: 40px 30px;
                border-left: 1px solid #E0E0E0;
                div{
                    margin-bottom: 20px;
                    p{
                        height: 32px;
                        line-height: 32px;
                        font-size: 16px;
                        font-family: 'PingFangSC, PingFang SC';
                        color: #000000;
                        span{
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 50%;
                            font-size: 14px;
                            font-family: 'PingFangSC, PingFang SC';
                            color: rgba(0,0,0,0.25);
                            margin-right: 8px;
                            border: 1px solid #E0E0E0;
                            text-align: center;
                            padding: 0;
                            position: relative;
                        }
                        span::after{
                            content: '';
                            width: 1px;
                            height: 48px;
                            background: #E0E0E0;
                            position: absolute;
                            left: 15.5px;
                            top: 40px;
                        }
                    }
                    span{
                        display: inline-block;
                        font-size: 14px;
                        font-family: 'PingFangSC, PingFang SC';
                        color: rgba(0,0,0,0.45);
                        line-height: 22px;
                        padding-left: 40px;
                    }
                }
                div:last-child{
                    p{
                        span::after{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px){
        .formTitle{
            padding: 8px 20px 0 20px!important;
        }
        .formContent{
            display: block!important;
            .content{
                width: 100%!important;
                padding: 0!important;
            }
            .contentTips{
                display: none;
            }
        }
    }
</style>
