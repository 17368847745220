<template>
  <div class="DCIDetail">
    <div class="detailTop">
        <div class="detailTitle">
        {{orderInfo.workName}} 
        <!-- <span>申领成功</span> -->
        <span class="stausCell"
              :class="{'stausCell1':orderInfo.status=='31',
              'stausCell2':orderInfo.status=='40',
              'stausCell3':orderInfo.status=='11'|| orderInfo.status=='13' || orderInfo.status=='13' || orderInfo.status=='20',
              'stausCell4':orderInfo.status=='10',
              'stausCell5':orderInfo.status=='21',}">{{orderInfo.statusText}}
        </span>
      </div>
      <button v-if="orderInfo.status == '21'" @click="goDCIRegister()">数登申请</button>
      <button v-else disabled>数登申请</button>
    </div>
    <p class="detailTitleDCI" v-if="orderInfo.dciCode"><i class="icon iconfont">&#xe605;</i> <span>{{orderInfo.dciCode}}</span> <i class="icon iconfont"></i></p>

    <div class="infoBox">
      <div class="title">作品信息</div>
      <div class="infoList">
        <div><span>作品类别</span><p>文字作品</p></div>
        <div><span>首次发表日期</span><p v-if="orderInfo.publicationDate">{{orderInfo.publicationDate}}</p></div>
        <div><span>作品文件</span><p class="infoFile" v-if="orderInfo.items"><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</p></div>
        <div><span>权利范围</span><p>全部</p></div>
        <div><span>创作性质</span><p>原创</p></div>
        <div><span>权利取得方式</span><p>原始</p></div>
        <div><span>发表状态</span><p>已发表</p></div>
        <div><span>创作完成日期</span><p v-if="orderInfo.creationCompletionDate">{{orderInfo.creationCompletionDate}}</p></div>
        <div><span>权利归属方式</span><p>个人作品</p></div>
        <div class="infoListBox2" v-if="orderInfo.status=='31'"><span>申领失败原因</span><p>{{orderInfo.remark}}</p></div>
      </div>
    </div>

    <div class="infoBox">
      <div class="title">申领信息</div>
      <div class="infoList">
        <div><span>申领人</span><p>{{orderInfo.authorName}}</p></div>
        <div><span>提交申领时间</span><p>{{orderInfo.createTimeText}}</p></div>
        <div><span>更新时间</span><p>{{orderInfo.updateTimeText}}</p></div>
      </div>
    </div>

    <!-- <div class="infoBox">
      <div class="title">DCI证书</div>
        <div class="infoCertificate">
          <a-row>
            <a-col :md="24" :lg="10" :xl="10">
              <p>温馨提示：</p>
              <span>此凭证由鹊凿版权保护平台出具，你可以对关键字段去到数字作品版权链进行 DCI 核验。</span>
              <button>下载DCI证书</button>
            </a-col>
            <a-col :md="24" :lg="4" :xl="4"></a-col>
            <a-col :md="24" :lg="10" :xl="10">
              <img src="../../../assets/images/aboutUsImg07.png" alt="">
            </a-col>
          </a-row>
        </div>
    </div> -->

    <div class="infoBox infoBox2" v-if="orderInfo.status=='21'">
      <div class="title">证书信息</div>
      <div class="infoList">
        <div><span>证书</span><div @click="getDCIImg(orderInfo.certificateInfo.jpgUrl)"><img :src="orderInfo.certificateInfo.jpgUrl" alt=""><div><img src="../../../assets/images/search.png" alt=""></div></div></div>
      </div>
    </div>



    <!-- 图片预览 -->
      <el-image-viewer
        v-if="showImageViewer"
        :url-list="[showImageUrl]"
        hide-on-click-modal
        teleported
        :on-close="closePre"
      />

  </div>
</template>

<script>
import { queryDCIOrder } from "@/api/EvidenceService"
export default {
  components: {'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),},
  props: {
      DCIOrderNo: String,
  },
  data () {
    return {
      // DCIOrderNo: "900-8358904-299671",

      orderInfo: {},
      showImageViewer: false,
      showImageUrl: '',
    }
  },
  created() {
    // console.log('DCIOrderNo---:',this.DCIOrderNo);
    // 获取DCI订单详细信息
    this.getQueryDCIOrder();
    let that = this;
    setInterval(function(){
      that.getQueryDCIOrder();
    },30000)
  },
  methods: {
    getQueryDCIOrder(){
      let that = this;
      queryDCIOrder(that.DCIOrderNo).then(({ data }) => {
        // console.log('获取DCI订单详细信息------',data);
          that.orderInfo = data
          if(that.orderInfo.createTime){
            that.orderInfo.createTimeText = that.dayjs(data.createTime * 1000).format("YYYY-MM-DD HH:mm:ss ");
          }
          if(that.orderInfo.updateTime){
            that.orderInfo.updateTimeText = that.dayjs(data.updateTime * 1000).format("YYYY-MM-DD HH:mm:ss ");
          }
          that.orderInfo.statusText=that.getStatus(data.status);
      });
    },

    getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '12':return '检测中';
          case '13':return '申领中';
          case '20':return '待处理';
          case '21':return '申领成功';
          case '31':return '申领失败';
          case '40':return '已取消';
        }
    },

    getDCIImg(url){
      document.body.style.overflow = 'hidden';
      this.showImageUrl = url;
      this.showImageViewer = true;
    },
    closePre(){
      document.body.style.overflow = 'auto';
      this.showImageUrl = '';
      this.showImageViewer = false;
    },

    // 跳转到第三部数字登记
    goDCIRegister(){
      this.$parent.changeStep('3')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/mine.scss';
.DCIDetail {
    .searchBar{
      height: 40px;
      .title{
        height: 40px;
        line-height: 40px;
      }
      .icon{
        font-weight: bold;
      }
    }
    .detailTop{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detailTitle{
        display: flex;
        align-items: center;
        max-width: calc(100% - 150px);
        font-size: 24px;
        font-family: 'PingFangSC, PingFang SC';
        font-weight: 500;
        color: rgba(0,18,22,0.85);
        line-height: 50px;
        span{margin-left: 12px;display: block;background: #E0FAEA;border-radius: 4px;border: 1px solid #BCEFD2;height: 22px;line-height: 22px;padding: 0 8px;font-size: 12px;color: #007F56;min-width: 60px;text-align: center;}
        span.stausCell1{
            color: #E93030;
            border-color: #E93030;
            background: #FAE5E0;
        }
        span.stausCell2{
            color: #A6A6A6;
            border-color: #A6A6A6;
            background:#F5F5F5;
        }
        span.stausCell3{
            color: #0063DA;
            border-color: #0063DA;
            background:#E0EFFA;
        }
        span.stausCell4{
            color: #DB5A00;
            border-color: #DB5A00;
            background:#FDEED7;
        }
        span.stausCell5{
            color: #007F56;
            border-color: #007F56;
            background: #E0FAEA;
        }
      }
      button{
        width: 130px;
        height: 40px;
        line-height: 38px;
        background: #027DFF;
        border-radius: 4px;
        font-size: 14px;
        font-family: 'PingFangSC, PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
      button:disabled{
          background: #ECEFF6;
          border: 1px solid #D9D9D9;
          color: #ACACAC;
          cursor: no-drop;
      }
    }
    
    .detailTitleDCI{
      display: flex;
      align-items: center;
      span{
        font-size: 16px;
        font-family: 'PingFangSC, PingFang SC';
        margin: 0 10px;
      }
      i:nth-child(1){
        color: #007DFF;
        font-size: 24px;
      }
    }

    .infoBox{
      padding: 20px 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px 0px rgba(0,70,144,0.09);
      margin: 20px 0;
      .title{
        font-size: 20px;
        font-family: 'PingFangSC, PingFang SC';
        font-weight: 500;
        color: rgba(0,18,22,0.85);
        line-height: 36px;
        position: relative;
        margin-bottom: 10px;
      }
      .title::before{content: '';width: 4px;height: 20px;border-radius: 2px;background: #027DFF;position: absolute;top: 8px;left: -12px;}
      .infoList{
        display: flex;
        flex-wrap: wrap;
	      justify-content: flex-start;
        div{
          width: 50%;
          display: flex;
          align-content: center;
          height: 40px;
          span{display: block;width: 130px;line-height: 40px;font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 500;color: rgba(0,18,22,0.45);}
          p{width: calc(100% - 130px);line-height: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;font-family: 'PingFangSC, PingFang SC';color: #001216;padding-right: 20px;}
          .infoFile{color: #316EF4;}
        }
        .infoListBox2{width: 100%;
          span{color: #E4403C;opacity: 1;}
        }
      }
      .infoCertificate{
        p{font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 500;color: rgba(0,18,22,0.65);line-height: 24px;margin-bottom: 10px;margin-top: 50px;}
        span{font-size: 12px;font-family: 'PingFangSC, PingFang SC';font-weight: 400;color: rgba(0,18,22,0.5);line-height: 20px;}
        button{display: block;padding: 0 10px;margin-top: 50px;height: 34px;line-height: 32px;background: #316EF4;color: #FFFFFF;border-radius: 4px;font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';
          font-weight: 500;border: none;outline: none;cursor: pointer;}
        img{width: 100%;}
      }
    }

    .infoBox2 .infoList div{height: 80px!important;}
    .infoBox2 .infoList div div{width: auto;cursor: pointer;position: relative;}
    .infoBox2 .infoList div div div{width: 100%;height: 100%;background: rgba(0,0,0,0.2);position: absolute;left: 0;top: 0;display: none;}
    .infoBox2 .infoList div div div img{width: 30px;height: 30px;margin: 25px auto;display: block;}
    .infoBox2 .infoList div div:hover div{display: block;}
}


@media screen and (max-width: 991px){
  .DCIDetail .infoBox .infoList div{width: 100%;}
  .DCIDetail .detailTitle{display: block;}
  .DCIDetail .detailTitle span{white-space: nowrap;display: inline-block;}
  .detailTop{display: block!important;}
  .detailTop .detailTitle{width: 100%!important;max-width: 100%!important;padding: 0 20px;}
  .detailTop button{margin: 20px;}
  .detailTitleDCI{padding: 0 20px;}
}

</style>
